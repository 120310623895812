var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h3", { staticStyle: { "line-height": "40px", "font-size": "14px" } }, [
      _vm._v("售后订单")
    ]),
    _c(
      "div",
      { staticClass: "order-table-wrapper" },
      [
        _c(
          "el-form-item",
          [
            _c(
              "el-table",
              {
                staticStyle: { width: "100%", "margin-bottom": "1px" },
                attrs: {
                  border: "",
                  stripe: "",
                  size: "mini",
                  "element-loading-text": "拼命加载中",
                  "row-style": { height: "48px" },
                  "cell-style": { padding: "0px" },
                  "header-cell-style": { background: "#e5f2ff" },
                  "header-cell-class-name": "normal-table-header",
                  data: _vm.orderTable
                }
              },
              [
                _vm._l(_vm.orderColumns, function(item) {
                  return [
                    item.prop === "payType"
                      ? _c("el-table-column", {
                          key: item.prop,
                          attrs: {
                            "show-overflow-tooltip": true,
                            label: item.label,
                            width: "120px"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var row = ref.row
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.payTypeMap[row.payType]) +
                                        " "
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      : item.prop === "allPay"
                      ? _c("el-table-column", {
                          key: item.prop,
                          attrs: {
                            "show-overflow-tooltip": true,
                            label: item.label
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var row = ref.row
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.allPayMap[row.allPay]) +
                                        " "
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      : item.prop === "goodsName"
                      ? _c("el-table-column", {
                          key: item.prop,
                          attrs: {
                            "show-overflow-tooltip": true,
                            label: item.label,
                            width: "180px",
                            fixed: item.fixed
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var row = ref.row
                                  return [
                                    _c("span", [_vm._v(_vm._s(row.goodsName))]),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          color: "#337AFF",
                                          "margin-top": "6px"
                                        }
                                      },
                                      [_vm._v(_vm._s(row.commodityName))]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      : item.prop === "orderStatus"
                      ? _c("el-table-column", {
                          key: item.prop,
                          attrs: {
                            "show-overflow-tooltip": true,
                            label: item.label,
                            width: "120px"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var row = ref.row
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.orderStatusMap[row.orderStatus]
                                        ) +
                                        " "
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      : item.prop === "afterSaleTypes"
                      ? _c("el-table-column", {
                          key: item.prop,
                          attrs: {
                            "show-overflow-tooltip": true,
                            label: item.label
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var row = ref.row
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(row.afterSaleTypes || "无") +
                                        " "
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      : item.prop === "createTime"
                      ? _c("el-table-column", {
                          key: item.prop,
                          attrs: {
                            "show-overflow-tooltip": true,
                            label: item.label,
                            width: "180px"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var row = ref.row
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("formatDate")(
                                            row.createTime,
                                            "YYYY-MM-DD HH:mm:ss"
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      : item.prop === "installmentType"
                      ? _c("el-table-column", {
                          key: item.prop,
                          attrs: {
                            "show-overflow-tooltip": true,
                            prop: item.prop,
                            label: item.label,
                            width: item.width
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var row = ref.row
                                  return [
                                    row.installmentType != 0
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.installmentTypeMap[
                                                row.installmentType
                                              ]
                                            )
                                          )
                                        ])
                                      : _c("span", [_vm._v("- -")])
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      : item.prop === "tradeSerialNumber"
                      ? _c("el-table-column", {
                          key: item.prop,
                          attrs: {
                            "show-overflow-tooltip": true,
                            prop: item.prop,
                            label: item.label,
                            width: item.width
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var row = ref.row
                                  return [
                                    row.installmentType != 0
                                      ? _c("span", [
                                          _vm._v(_vm._s(row.tradeSerialNumber))
                                        ])
                                      : _c("span", [_vm._v("- -")])
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      : _c("el-table-column", {
                          key: item.prop,
                          attrs: {
                            "show-overflow-tooltip": true,
                            prop: item.prop,
                            label: item.label,
                            width: item.width,
                            fixed: item.fixed
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var row = ref.row
                                  return [
                                    [
                                      "productName",
                                      "goodsSpecName",
                                      "goodsTypeName",
                                      "goodsSeriesName",
                                      "signSchoolName"
                                    ].includes(item.prop)
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("empty")(
                                                row[item.prop],
                                                "--"
                                              )
                                            )
                                          )
                                        ])
                                      : _c("span", [
                                          _vm._v(_vm._s(row[item.prop]))
                                        ])
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                  ]
                })
              ],
              2
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }